import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import eventSaga from "./events/saga"
import userSaga from "./users/saga"
import adminProfileSaga from "./profile/saga"
import adminSettingSaga from "./admin-settings/saga"
import bookedEventSaga from "./bookedEvent/saga"
import planSaga from "./plans/saga"
import userSubscriptionSaga from "./subscription/saga"
import siteInfoSaga from "./site-info/saga"
// import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import jobsSaga from "./jobs/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
import contactsSaga from "./contacts/saga"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import dashboardCryptoSaga from "./dashboard-crypto/saga"
import dashboardBlogSaga from "./dashboard-blog/saga"
import dashboardJobSaga from "./dashboard-jobs/saga"
import purchaseEventSaga from "./purchase/saga"
import contentSaga from "./contents/saga"
import categorySaga from "./categories/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),

    fork(eventSaga),
    fork(userSaga),
    fork(bookedEventSaga),
    fork(planSaga),
    fork(userSubscriptionSaga),
    fork(contentSaga),
    fork(categorySaga),
    fork(siteInfoSaga),

    fork(adminProfileSaga),
    fork(adminSettingSaga),

    // fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(jobsSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardBlogSaga),
    fork(dashboardJobSaga),
    fork(purchaseEventSaga),
  ])
}
