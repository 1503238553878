import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_PLANS,
  ADD_PLAN,
  DELETE_PLAN,
  GET_PLAN_DETAIL,
  UPDATE_PLAN,
} from "./actionTypes"
import {
  addPlanSuccess,
  addPlanFail,
  getPlansSuccess,
  getPlansFail,
  getPlanDetailSuccess,
  deletePlanSuccess,
  deletePlanFail,
  getPlanDetailFail,
  updatePlanSuccess,
  updatePlanFail,
} from "./actions"

import { showLoader, hideLoader } from "store/actions"

//Include Both Helper File with needed methods
import {
  getEventFullDetail,
  getEventsFullData,
} from "helpers/fakebackend_helper"
import { del, get, putt, post } from "helpers/api_helper"

function* fetchPlans() {
  try {
    yield put(showLoader())
    const response = yield call(get, "/api/v1/admin/users/plans/")

    yield put(getPlansSuccess(response.results))
  } catch (error) {
    yield put(getPlansFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* updatePlan({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(
      putt,
      `/api/v1/admin/users/plans/${data.id}/`,
      data,
    )
    yield put(updatePlanSuccess(response))
  } catch (error) {
    yield put(updatePlanFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* fetchPlanDetail({ payload: id }) {
  try {
    yield put(showLoader())
    const response = yield call(get, `/api/v1/admin/users/plans/${id}`)

    yield put(getPlanDetailSuccess(response))
  } catch (error) {
    yield put(getPlanDetailFail(error))
  } finally {
    yield put(hideLoader())
  }
}
function* onDeletePlan({ payload: id }) {
  try {
    yield put(showLoader())
    const response = yield call(del, `/api/v1/admin/users/plans/${id}`)
    yield put(deletePlanSuccess(response))
  } catch (error) {
    yield put(deletePlanFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* addPlan({ payload: data }) {
  try {
    yield put(showLoader())
    const response = yield call(post, "/api/v1/admin/users/plans/", data)
    yield put(addPlanSuccess(response))
  } catch (error) {
    yield put(addPlanFail(error))
  } finally {
    yield put(hideLoader())
  }
}

function* planSaga() {
  yield takeEvery(GET_PLANS, fetchPlans)
  yield takeEvery(GET_PLAN_DETAIL, fetchPlanDetail)
  yield takeEvery(DELETE_PLAN, onDeletePlan)
  yield takeEvery(UPDATE_PLAN, updatePlan)
  yield takeEvery(ADD_PLAN, addPlan)
}

export default planSaga
