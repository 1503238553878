import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap"

import {
  getSiteInfo,
  updateSiteInfo,
  addSiteInfo,
  resetSiteInfoAlert,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import Form from "react-bootstrap/Form"

const SiteSetting = () => {
  //meta title
  document.title = "Site Settings | Tacticulture - Admin"

  const dispatch = useDispatch()

  // States for SMTP
  const [id, setId] = useState(null)
  const [facebook, setFacebook] = useState(0)
  const [twitter, setTwitter] = useState("")
  const [instagram, setInstagram] = useState("")
  const [youtube, setYoutube] = useState("")
  const [detail, setDetail] = useState("")
  const [address, setAddress] = useState("")
  const [copyRight, setCopyRight] = useState("")

  const {
    siteInfo,
    updateSiteInfoError,
    updateSiteInfoSuccess,
    addSiteInfoSuccess,
    addSiteInfoError,
  } = useSelector(state => ({
    siteInfo: state.siteInfo.siteInfo,
    updateSiteInfoError: state.siteInfo.updateSiteInfoError?.response?.data,
    updateSiteInfoSuccess: state.siteInfo.updateSiteInfoSuccess,
    addSiteInfoSuccess: state.siteInfo.addSiteInfoSuccess,
    addSiteInfoError: state.siteInfo.addSiteInfoError?.response?.data,
  }))

  useEffect(() => {
    // if (adminProfile) {
    dispatch(getSiteInfo())
    // }
  }, [dispatch])

  useEffect(() => {
    if (
      updateSiteInfoError ||
      updateSiteInfoSuccess ||
      addSiteInfoError ||
      addSiteInfoSuccess
    ) {
      const timer = setTimeout(() => {
        dispatch(resetSiteInfoAlert())
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [
    updateSiteInfoError,
    updateSiteInfoSuccess,
    addSiteInfoError,
    addSiteInfoSuccess,
    dispatch,
  ])

  useEffect(() => {
    if (siteInfo) {
      setId(siteInfo.id || null)
      setFacebook(siteInfo.social_link_facebook)
      setTwitter(siteInfo.social_link_X)
      setInstagram(siteInfo.social_link_instagram)
      setYoutube(siteInfo.social_link_youtube)
      setDetail(siteInfo.company_details)
      setAddress(siteInfo.company_address)
      setCopyRight(siteInfo.website_copyright)
    }
  }, [siteInfo])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <h5 className="mb-4">Profile</h5> */}
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="Profile" breadcrumbItem="" /> */}

          <Row>
            <Col lg="12">
              {updateSiteInfoError && (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 1000,
                  }}
                >
                  {JSON.stringify(updateSiteInfoError)}
                </Alert>
              )}
              {addSiteInfoError && (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {JSON.stringify(addSiteInfoError)}
                </Alert>
              )}
              {updateSiteInfoSuccess && (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {updateSiteInfoSuccess}
                </Alert>
              )}
              {addSiteInfoSuccess && (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {addSiteInfoSuccess}
                </Alert>
              )}
              <div className="">
                <h3
                  style={{
                    fontSize: "20px",
                    borderBottom: "1px solid #000",
                    paddingBottom: "9px",
                    margin: "18px 0",
                  }}
                >
                  Site Settings
                </h3>
              </div>

              <Card>
                {/* <div className="">
                  <h3
                    style={{
                      fontSize: "18px",
                      borderBottom: "1px solid #000",
                      paddingBottom: "9px",
                      margin: "18px 18px 0",
                    }}
                  >
                    Manage Social Links
                  </h3>
                </div> */}
                <CardBody>
                  <div className="container-fluid p-0">
                    <div className="row fieldDetails">
                      <div className="col-md-12 field-spacing">
                        <Form.Label>Company Info</Form.Label>
                        <Form.Control
                          type="text"
                          value={detail}
                          onChange={e => setDetail(e.target.value)}
                          placeholder="Company Info"
                        />
                      </div>
                      <div className="col-md-12 field-spacing">
                        <Form.Label>Company Address</Form.Label>
                        <Form.Control
                          type="text"
                          value={address}
                          onChange={e => setAddress(e.target.value)}
                          placeholder="Company Address"
                        />
                      </div>
                      <div className="col-md-12 field-spacing">
                        <Form.Label>Copyright</Form.Label>
                        <Form.Control
                          type="text"
                          value={copyRight}
                          onChange={e => setCopyRight(e.target.value)}
                          placeholder="© 2024"
                        />
                      </div>
                      <div className="col-md-12 field-spacing">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control
                          type="text"
                          value={facebook}
                          onChange={e => setFacebook(e.target.value)}
                          placeholder="Facebook URL"
                        />
                      </div>
                      <div className="col-md-12 field-spacing">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                          type="text"
                          value={instagram}
                          onChange={e => setInstagram(e.target.value)}
                          placeholder="Instagram URL"
                        />
                      </div>
                      <div className="col-md-12 field-spacing">
                        <Form.Label>X (Twitter)</Form.Label>
                        <Form.Control
                          type="text"
                          value={twitter}
                          onChange={e => setTwitter(e.target.value)}
                          placeholder="Twitter URL"
                        />
                      </div>

                      <div className="col-md-12 field-spacing">
                        <Form.Label>Youtube</Form.Label>
                        <Form.Control
                          type="text"
                          value={youtube}
                          onChange={e => setYoutube(e.target.value)}
                          placeholder="Youtube URL"
                        />
                      </div>

                      <div style={{ textAlign: "end" }}>
                        <button
                          onClick={() => {
                            const payload = {
                              id: id,
                              social_link_facebook: facebook,
                              social_link_X: twitter,
                              social_link_instagram: instagram,
                              social_link_youtube: youtube,
                              company_details: detail,
                              company_address: address,
                              website_copyright: copyRight,
                            }
                            if (siteInfo === undefined) {
                              dispatch(addSiteInfo(payload))
                            } else {
                              dispatch(updateSiteInfo(payload))
                            }
                          }}
                          style={{
                            background: "#556ee6",
                            width: "144px",
                            height: "44px",
                            border: "0",
                            fontSize: "16px",
                            borderRadius: "26px",
                            margin: "18px 0",
                            color: "#fff",
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SiteSetting
