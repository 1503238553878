import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import dummy from "../../../assets/images/users/avatar_dummy.jpeg"
import Switch from "react-switch"

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const CommonField = cell => {
  return cell.value ? cell.value : ""
}

const PlanCost = cell => {
  return cell.value === 0 ? "$0" : cell.value ? `$${cell.value}` : ""
}
const SwitchField = cell => {
  return <Switch checked={cell.value} onChange={cell.onChange} />
}

const EventName = cell => {
  return cell.value ? cell.value : ""
}

const Image = cell => {
  return cell.value ? (
    <img
      src={cell.value}
      alt="User Avatar"
      className="rounded-circle img-fluid"
    />
  ) : (
    <img src={dummy} alt="User Avatar" className="rounded-circle img-fluid" />
  )
}

const Date = cell => {
  return cell.value ? moment(cell.value).format("DD MMM y") : ""
}

const Total = cell => {
  return cell.value ? cell.value : ""
}

const PaymentStatus = cell => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" +
        (cell.value === "Paid"
          ? "success"
          : "danger" && cell.value === "Refund"
            ? "warning"
            : "danger")
      }
    >
      {cell.value}
    </Badge>
  )
}
const PaymentMethod = cell => {
  return (
    <span>
      <i
        className={
          cell.value === "Paypal"
            ? "fab fa-cc-paypal me-1"
            : "" || cell.value === "COD"
              ? "fab fas fa-money-bill-alt me-1"
              : "" || cell.value === "Mastercard"
                ? "fab fa-cc-mastercard me-1"
                : "" || cell.value === "Visa"
                  ? "fab fa-cc-visa me-1"
                  : ""
        }
      />{" "}
      {cell.value}
    </span>
  )
}
export {
  OrderId,
  EventName,
  Date,
  Total,
  PlanCost,
  PaymentStatus,
  PaymentMethod,
  CommonField,
  Image,
  SwitchField,
}
