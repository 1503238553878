import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import Lefts from "../../../assets/images/lefts.png"
import { useNavigate } from "react-router-dom"
import { useSearchParams } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import Form from "react-bootstrap/Form"
import { getCategoryDetail, getCategories, updateCategory } from "store/actions"
// actions
// import { editProfile, resetProfileFlag } from "../../store/actions"

export default function CategoryDetails() {
  //meta title
  document.title = "Category | Tacticulture - Admin"
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const categoryId = searchParams.get("categoryId")

  const [idx, setidx] = useState(null)
  const [categoryName, setCategoryName] = useState("")
  const [categorySlug, setCategorySlug] = useState("")

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const categoryDetail = useSelector(state => state.Categories.categoryDetail)

  useEffect(() => {
    if (categoryId && Object.keys(categoryDetail).length === 0) {
      dispatch(getCategoryDetail(categoryId))
    }
  }, [dispatch, categoryId, categoryDetail])

  useEffect(() => {
    if (categoryDetail) {
      setidx(categoryDetail.id)
      setCategoryName(categoryDetail.event_categories)
      setCategorySlug(categoryDetail.slug_name)
    }
  }, [categoryDetail])

  return (
    <React.Fragment>
      <button
        onClick={() => navigate(-1)}
        style={{
          position: "relative",
          top: "95px",
          left: "21px",
          marginBottom: "20px",
          background: "#556ee6",
          width: "74px",
          height: "36px",
          border: "0",
          color: "#fff",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <img
          src={Lefts}
          alt=""
          height="17"
          style={{ transform: "rotate(180deg)" }}
        />{" "}
        Back
      </button>
      <div className="page-content">
        <Container fluid>
          {/* <h5 className="mb-4">Profile</h5> */}
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="Profile" breadcrumbItem="" /> */}

          <Row>
            <Col lg="12">
              {error && error ? (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {error}
                </Alert>
              ) : null}
              {success ? (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {success}
                </Alert>
              ) : null}

              <div className="">
                <h3
                  style={{
                    fontSize: "20px",
                    borderBottom: "1px solid #000",
                    paddingBottom: "9px",
                    margin: "18px 0",
                  }}
                >
                  Edit Category
                </h3>
              </div>

              <Card>
                <CardBody>
                  <div className="container-fluid p-0">
                    <div className="row fieldDetails">
                      <div className="col-md-12">
                        <Form.Label>Category Id</Form.Label>
                        <Form.Control
                          type="number"
                          value={idx}
                          placeholder="Enter Your Category Id"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={categoryName}
                          onChange={e => setCategoryName(e.target.value)}
                          placeholder="Category Name"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Category Slug</Form.Label>
                        <Form.Control
                          type="text"
                          value={categorySlug}
                          onChange={e => setCategorySlug(e.target.value)}
                          placeholder="Category Slug"
                          isInvalid={
                            !/^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(categorySlug) &&
                            categorySlug !== ""
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Slug must contain only lowercase letters, numbers, and
                          hyphens, and cannot start or end with a hyphen.
                        </Form.Control.Feedback>
                      </div>

                      <div style={{ textAlign: "end" }}>
                        <button
                          onClick={() => {
                            const payload = {
                              id: idx,
                              event_categories: categoryName,
                              slug_name: categorySlug,
                            }
                            dispatch(updateCategory(payload))
                            setTimeout(() => {
                              dispatch(getCategories())
                            }, 400)
                          }}
                          style={{
                            background: "#556ee6",
                            width: "144px",
                            height: "44px",
                            border: "0",
                            fontSize: "16px",
                            borderRadius: "26px",
                            margin: "18px 0",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
