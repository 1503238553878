import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import Lefts from "../../../assets/images/lefts.png"
import { useNavigate } from "react-router-dom"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import Form from "react-bootstrap/Form"
import { addPlan } from "store/actions"
// actions
// import { editProfile, resetProfileFlag } from "../../store/actions"

export default function AddPlan() {
  //meta title
  document.title = "Plan | Tacticulture - Admin"
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [planName, setPlanName] = useState("")
  const [planDescription, setPlanDescription] = useState("")
  const [planDuration, setPlanDuration] = useState("")
  const [planCost, setPlanCost] = useState("")

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const handlePlanDescriptionChange = value => {
    setPlanDescription(value)
  }

  return (
    <React.Fragment>
      <button
        onClick={() => navigate(-1)}
        style={{
          position: "relative",
          top: "95px",
          left: "21px",
          marginBottom: "20px",
          background: "#556ee6",
          width: "74px",
          height: "36px",
          border: "0",
          color: "#fff",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <img
          src={Lefts}
          alt=""
          height="17"
          style={{ transform: "rotate(180deg)" }}
        />{" "}
        Back
      </button>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {error && error ? (
                <Alert
                  color="danger"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {error}
                </Alert>
              ) : null}
              {success ? (
                <Alert
                  color="success"
                  style={{
                    position: "fixed",
                    top: "80px",
                    right: "10px",
                    zIndex: 1000,
                  }}
                >
                  {success}
                </Alert>
              ) : null}

              <div className="">
                <h3
                  style={{
                    fontSize: "20px",
                    borderBottom: "1px solid #000",
                    paddingBottom: "9px",
                    margin: "18px 0",
                  }}
                >
                  Add Plan
                </h3>
              </div>

              <Card>
                <CardBody>
                  <div className="container-fluid p-0">
                    <div className="row fieldDetails">
                      <div className="col-md-12">
                        <Form.Label>Plan Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={planName}
                          onChange={e => setPlanName(e.target.value)}
                          placeholder="Enter Your Plan Name"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Plan Description</Form.Label>
                        <ReactQuill
                          showCount
                          maxLength={1000}
                          //   style={{ height: "300px" }}
                          value={planDescription}
                          onChange={handlePlanDescriptionChange}
                          placeholder="Enter Your Plan Description"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Plan Type</Form.Label>
                        <div>
                          <Form.Control
                            as="select"
                            value={planDuration}
                            onChange={e => {
                              const selectedDuration = e.target.value
                              setPlanDuration(selectedDuration)
                              // Automatically set plan cost to 0 if the selected duration is "free"
                              if (selectedDuration === "free") {
                                setPlanCost(0)
                              }
                            }}
                          >
                            <option value="">Select Type</option>
                            <option value="month">Monthly</option>
                            <option value="year">Yearly</option>
                            <option value="free">Free</option>
                          </Form.Control>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Plan Cost ($)</Form.Label>
                        <Form.Control
                          type="number"
                          disabled={planDuration === "free" ? true : false}
                          value={planCost}
                          onChange={e => setPlanCost(e.target.value)}
                          placeholder="Enter Your Plan Cost"
                        />
                      </div>

                      <div style={{ textAlign: "end" }}>
                        <button
                          onClick={() => {
                            const payload = {
                              name: planName,
                              description: planDescription,
                              pricing: planCost,
                              status: true,
                              plan_type: planDuration,
                            }
                            dispatch(addPlan(payload))
                            navigate("/plans")
                          }}
                          style={{
                            background: "#556ee6",
                            width: "144px",
                            height: "44px",
                            border: "0",
                            fontSize: "16px",
                            borderRadius: "26px",
                            margin: "18px 0",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
