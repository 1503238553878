import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  deleteEvent,
  getEventDetail,
  getEvents,
  updateEvent,
} from "store/actions"

import {
  OrderId,
  EventName,
  Date as EventDate,
  Total,
  PaymentStatus,
  PaymentMethod,
  CommonField,
  CourseUrl,
  ProfileUrl,
  SwitchField,
  EventCost,
} from "./EventsCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import { events } from "common/data"
import EcommerceOrdersModal from "pages/Ecommerce/EcommerceOrders/EcommerceOrdersModal"
import MessageModal from "components/Common/MessageModal"

function Events() {
  const navigate = useNavigate()
  //meta title
  document.title = "Events | Tacticulture-Admin"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [orderList, setOrderList] = useState([])
  const [event, setEvent] = useState(null)
  const [switchValue, setSwitchValue] = useState("")

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (event && event.orderId) || "",
      eventName: (event && event.eventName) || "",
      orderdate: (event && event.orderdate) || "",
      total: (event && event.total) || "",
      paymentStatus: (event && event.paymentStatus) || "Paid",
      badgeclass: (event && event.badgeclass) || "success",
      paymentMethod: (event && event.paymentMethod) || "Mastercard",
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Order Id")
        .required("Please Enter Your Order Id"),
      eventName: Yup.string().required("Please Enter Your Event Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Amount")
        .required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateOrder = {
          id: event ? event.id : 0,
          orderId: values.orderId,
          eventName: values.eventName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        }
        // update event
        dispatch(onUpdateOrder(updateOrder))
        validation.resetForm()
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          eventName: values["eventName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        }
        // save new event
        dispatch(onAddNewOrder(newOrder))
        validation.resetForm()
      }
      toggle()
    },
  })

  const toggleViewModal = id => {
    dispatch(getEventDetail(id))
    navigate(`/event-detail?eventId=${id}`)
    // setModal1(!modal1)
  }

  const dispatch = useDispatch()
  const { events } = useSelector(state => ({
    events: state.events.events,
  }))

  useEffect(() => {
    if (events && !events.length) {
      dispatch(getEvents())
    }
  }, [dispatch])

  // Sorting events by event date (latest first)
  const sortedEvents = useMemo(() => {
    return events.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at),
    )
  }, [events])
  //   useEffect(() => {
  //     setOrderList(events)
  //   }, [events])

  //   useEffect(() => {
  //     if (!isEmpty(orders) && !!isEdit) {
  //       setOrderList(orders)
  //       setIsEdit(false)
  //     }
  //   }, [orders])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setEvent(null)
    } else {
      setModal(true)
    }
  }

  const handleOrderClick = arg => {
    const event = arg
    setEvent({
      id: event.id,
      orderId: event.orderId,
      eventName: event.eventName,
      orderdate: event.orderdate,
      total: event.total,
      paymentStatus: event.paymentStatus,
      paymentMethod: event.paymentMethod,
      badgeclass: event.badgeclass,
    })

    setIsEdit(true)

    toggle()
  }

  //delete event
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = event => {
    setEvent(event)
    setDeleteModal(true)
  }

  const handleDeleteEvent = () => {
    if (event && event.id) {
      dispatch(deleteEvent(event.id))
      setDeleteModal(false)
    }
  }
  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Event ID",
        accessor: "id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Event Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <EventName {...cellProps} />
        },
      },
      {
        Header: "Instructor",
        accessor: "created_by",
        filterable: true,
        Cell: cellProps => {
          return <ProfileUrl {...cellProps} />
        },
      },
      {
        Header: "Created Date",
        accessor: "created_at",
        filterable: true,
        Cell: cellProps => {
          return <EventDate {...cellProps} />
        },
      },
      {
        Header: "Course Url",
        accessor: "course_url",
        filterable: true,
        Cell: cellProps => {
          return <CourseUrl {...cellProps} />
        },
      },
      {
        Header: "Event Status",
        accessor: "is_event_active",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <SwitchField
                {...cellProps}
                onChange={value => {
                  dispatch(updateEvent(cellProps.row.original.id, value))
                  setSwitchValue(value)
                }}
              />
              {/* <MessageModal showMessage={true} text={switchValue} /> */}
            </>
          )
        },
      },
      {
        Header: "Event Cost",
        accessor: "cost_per_spot",
        filterable: true,
        Cell: cellProps => {
          return <EventCost {...cellProps} />
        },
      },
      {
        Header: "Available Spots",
        accessor: "available_spots",
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "View Details",
        accessor: "view",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal(cellProps.row.original.id)}
            >
              View Details
            </Button>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original
                  handleOrderClick(orderData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [],
  )

  const handleRowClick = row => {
    dispatch(getEventDetail(row.original.id))
    navigate(`/event-detail?eventId=${row.original.id}`) // Navigate to event detail page based on event id
  }

  return (
    <React.Fragment>
      {/* <EventsDetailModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Manage Events</h3>
          {/* <Breadcrumbs title="Events" breadcrumbItem="List" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={sortedEvents}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    getRowProps={row => ({
                      onClick: e => {
                        if (
                          !e.target.closest("[data-column='course_url']") &&
                          !e.target.closest("[data-column='status']") &&
                          !e.target.closest("[data-column='action']") &&
                          !e.target.closest("[data-column='created_by']")
                        ) {
                          handleRowClick(row)
                        }
                      },
                      style: { cursor: "pointer" }, // Make cursor pointer for rows
                    })}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                    forFilter={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Order" : "Add Order"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Order Id</Label>
                      <Input
                        name="orderId"
                        type="text"
                        placeholder="Insert Order Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderId || ""}
                        invalid={
                          validation.touched.orderId &&
                          validation.errors.orderId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.orderId &&
                      validation.errors.orderId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.orderId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Event Name</Label>
                      <Input
                        name="eventName"
                        type="text"
                        placeholder="Insert Event Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.eventName || ""}
                        invalid={
                          validation.touched.eventName &&
                          validation.errors.eventName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.eventName &&
                      validation.errors.eventName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.eventName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Order Date</Label>
                      <Input
                        name="orderdate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderdate || ""}
                        invalid={
                          validation.touched.orderdate &&
                          validation.errors.orderdate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.orderdate &&
                      validation.errors.orderdate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.orderdate}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ""}
                        invalid={
                          validation.touched.total && validation.errors.total
                            ? true
                            : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">
                          {validation.errors.total}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ""}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ""}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}
// EcommerceOrder.propTypes = {
//   preGlobalFilteredRows: PropTypes.any,
// }

export default Events
